import { initAuth } from '@/auth'

export default {
  init (state, reInit) {
    if (!state.ready || reInit) {
      console.log('mutation init() running and going ...')

      initAuth()
        .then((res) => {
          console.log('mutation init() init() complete')
        })
        .catch(e => {
          console.error('mutation init() initAuth')
        })
    }
  },
  setLanguage (state, obj) {
    state.language = obj
  },
  setSpinnerState (state, bool) {
    state.showSpinner = bool
  },
  setModalState (state, obj) {
    state.modal = Object.assign({}, state.modal, obj)
  },
  // Obsolete: Use setBannersList
  setCompanyList (state, list) {
    const bannerNamesPreferredDisplayOrder = ['Platt', 'Rexel', 'Gexpro', 'Mayer', 'Rexel Energy', 'Capitol Light', 'Parts Super Center', 'Westburne Quebec', 'Westburne Ontario', 'Westburne Midwest', 'Westburne West', 'Nedco Quebec', 'Nedco Ontario', 'Nedco West', 'Rexel Atlantic']
    const orderedBanners = []
    bannerNamesPreferredDisplayOrder.forEach((item, index) => {
      const foundIndex = list.findIndex(b => b.name === item)
      orderedBanners.push(list[foundIndex])
    })
    state.companyList = orderedBanners
  },
  setBannersList (state, list) {
    const bannerNamesPreferredDisplayOrder = ['Platt', 'Rexel', 'Gexpro', 'Mayer', 'Rexel Energy', 'Capitol Light', 'Parts Super Center', 'Rexel Canada']
    const orderedBanners = []
    bannerNamesPreferredDisplayOrder.forEach((item, index) => {
      const foundIndex = list.findIndex(b => b.name === item)
      orderedBanners.push(list[foundIndex])
    })
    state.banners = orderedBanners
  },
  setPagination (state, page) {
    state.pagination = page
  },
  setUsersList (state, list) {
    state.napmUsers = list
  },
  setTeamList (state, list) {
    state.teamList = list
  },
  setProductBulkList (state, array) {
    state.productBulkList = array
  },
  setCategory (state, obj) {
    state.categories[obj.key] = obj.value
  },
  setVendors (state, vendors) {
    state.vendors = vendors
  },
  setManufacturers (state, result) {
    state.manufacturers = result
  },
  setDocumentTypeIds (state, result, language) {
    state.documentTypeIds[language] = result
  },
  setUser (state, user) {
    state.user = user
  },
  setAccessToken (state, token) {
    state.accessToken = token
  },
  setAppConfig (state, data) {
    console.log(`api env: ${data.environment}`)
    state.appConfig = data
  },
  setPermissions (state, data) {
    state.permissions = data
  },
  setIdentity (state, identity) {
    console.log(`user: ${identity.email}`)
    if (identity == null || Object.keys(identity).length === 0) {
      return null
    }
    // all users w/ an identity should have role, which should contain a list of json as strings describing each role available to the user.
    if (identity.role == null) {
      return null
    }
    state.identity = identity
  },
  setExternalUrl (state, data) {
    state.externalUrl = data
  },
  setAlternativeCategories (state, list) {
    state.alternativeCategories = list
  }
}
