<template>
  <div class="alternative-category">
    <page-title
      title="Alternative Categories"
      :route="from"></page-title>
    <v-sheet
      class="pa-4 lighten-3"
      elevation="1">
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="search"
            label="Search Categories"
            solo
            dense
            clearable
            clear-icon="search_off"
            @input="filterTree">
          </v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="my-2">
        <v-col cols="4">
          <v-row no-gutters>
            <v-col cols="11">
              <div
                class="elevation-1"
                style="height: 500px; overflow-y: auto; cursor: pointer;">
                <v-treeview
                  class="mt-4"
                  :items="mappedCategoryItems"
                  :search="search"
                  :filter="filter"
                  :open.sync="open"
                  activatable
                  hoverable
                  transition
                  dense
                  open-all
                  :collapse-icon="'more_vert'"
                  @update:active="selectCategory">
                </v-treeview>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="1">
              <v-btn
                icon
                @click="toggleTreeView">
                <v-icon
                  v-if="isExpanded"
                  dark
                  x-large>
                  expand_less
                </v-icon>
                <v-icon
                  v-else
                  dark
                  x-large>
                  expand_more
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col
          v-if="selectedCategory"
          cols="8">
          <v-row>
            <span class="text-h5 ml-2">
              {{ getCategoryName(selectedCategory) }} (ID {{ selectedCategory.alternativeCategoryId }})
            </span>
          </v-row>
          <v-row>
            <v-col
              class="mt-2"
              cols="2">
              <details-media
                :details.sync="selectedCategory.imageDetails"
                :from-alternative-categories="true"
                @save="imageCopyOrUpload($event, true)">
              </details-media>
            </v-col>
            <v-col
              cols="8"
              class="m-0 p-0">
              <v-card
                flat>
                <v-card-text class="text-h6">
                  <div>
                    Main Hierarchy Level 1 Category: <strong>{{ selectedCategory.napmCategory?.name }}</strong>
                  </div>
                  <div>
                    Mach2 Classification: <strong>{{ selectedCategory.mach2CategoryLabel }}</strong>
                  </div>
                  <div>Media Id: <strong>{{ selectedCategory.mediaId }}</strong></div>
                  <div>Company: <strong>{{ getCompany(selectedCategory.companyId).name }}</strong></div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="1">
              <v-menu>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on">
                    <v-icon>
                      more_vert
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in actionItems"
                    :key="index"
                    :disabled="actionDisabled(item.action)"
                    @click="doAction(item.action)">
                    <v-list-item-title>
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-else
          cols="8">
          <v-row>
            <v-spacer></v-spacer>
            <v-menu>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on">
                  <v-icon>
                    more_vert
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="doAction('addRootCategory')">
                  <v-list-item-title>
                    Add Root Category
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
    <v-dialog
      v-model="showEditDialog"
      max-width="800px">
      <v-card>
        <v-card-title>
          <span>Category Details</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="isFormValid">
            <v-text-field
              v-model="editCategory.categoryName"
              label="Category Name"
              hide-details
              dense
              outlined
              :rules="[rules.max, rules.min]">
            </v-text-field>
            <v-row
              class="mt-8">
              <v-col cols="7">
                <v-select
                  v-model="selectLevel1CategoryLegacy"
                  :items="legacyLevel1Categories"
                  :disabled="disableLevelCategoryLegacy"
                  :rules="[isAddRoot ? true : v => !!v || 'Required']"
                  label="Main Hierarchy Level 1 Category"
                  :item-text="getLevel1CategoryLegacyItemText"
                  item-value="categoryId"
                  return-object
                  dense
                  @change="setLevel1CategoryLegacy($event)">
                </v-select>
              </v-col>
              <v-col cols="1">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on">
                      info
                    </v-icon>
                  </template>
                  <div style="max-width: 300px;">
                    Top-level categories in the alternative category hierarchy must be associated to a category in the main category hierarchy for reporting purposes. At lower levels of the alternative category hierarchy, the association to the main category hierarchy is inherited from the parent category. It is possible to override the inherited value by specifying a different value if needed.
                  </div>
                </v-tooltip>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4">
                <v-checkbox
                  v-if="(!isAddRoot && !isRootCategory) || isAddChild"
                  v-model="level1LegacyCategoryOverride"
                  dense
                  label="Override Inherited Value"
                  @change="setOverrideLevel1CategoryLegacy()">
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row
              class="mt-6">
              <v-col cols="7">
                <v-text-field
                  v-model="editCategory.mach2CategoryLabel"
                  :label="isAddRoot ? '(Based on Level 1 Category Selection)' : 'Mach2 Classification'"
                  dense
                  outlined
                  hide-details
                  :disabled="!mach2CategoryLabelOverride"
                  :rules="[rules.max, rules.min]">
                </v-text-field>
              </v-col>
              <v-col cols="1">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on">
                      info
                    </v-icon>
                  </template>
                  <div style="max-width: 300px;">
                    Top-level categories in the alternative category hierarchy inherit their MACH2 classification from the main category association by default. At lower levels of the alternative category hierarchy, the MACH2 classification is inherited from the parent category. It is possible to override the inherited value by specifying a different value if needed.
                  </div>
                </v-tooltip>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4">
                <v-checkbox
                  v-model="mach2CategoryLabelOverride"
                  dense
                  label="Override Inherited Value"
                  @change="setOverrideMach2CategoryLabel()">
                </v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="cancel()">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="showSpinner || !isFormValid"
            @click="save()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showDeleteDialog"
      max-width="300px">
      <v-card>
        <v-card-title>
          <span>Delete Category</span>
        </v-card-title>
        <v-card-title>{{ selectedCategory?.name }}</v-card-title>
        <v-card-text>
          <span>Are you sure you want to delete this category?</span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="cancel()">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="showSpinner"
            @click="deleteCategory()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      v-if="showSpinner"
      class="spinner show-spinner">
    </div>
    <v-snackbar
      v-model="showSnackbar"
      color="red">
      {{ snackMessage }}
      <template #action="{ attrs }">
        <v-btn
          text
          color="white"
          v-bind="attrs"
          outlined
          @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios'
import DetailsMedia from '../components/detailsMedia'
import TitleComponent from 'components/pageTitle'

export default {
  name: 'AlternativeCategory',
  components: {
    detailsMedia: DetailsMedia,
    'page-title': TitleComponent
  },
  data () {
    return {
      alternativeCategories: [], // Data from BE
      allIds: [],
      mappedCategoryItems: [], // Used by TreeView
      legacyLevel1Categories: [],
      search: null,
      caseSensitive: false,
      selectedCategory: null,
      open: [],
      editCategory: {
        categoryName: null,
        mach2CategoryLabel: null
      },
      action: null,
      details: {
        mediaId: null,
        media: null
      },
      actionItems: [
        {
          title: 'Edit Category',
          action: 'editCategory',
          disabled: true
        },
        {
          title: 'Add Child Category',
          action: 'addChildCategory',
          disabled: true
        },
        {
          title: 'Delete',
          action: 'showDeleteDialog',
          disabled: true
        }
      ],
      showEditDialog: false,
      showDeleteDialog: false,
      showSpinner: false,
      selectLevel1CategoryLegacy: {},
      rules: {
        max: v => v?.length <= 100 || 'Max 100 characters',
        min: v => v?.length >= 4 || 'Min 4 characters'
      },
      defaultCompanyId: 15,
      doPost: true, // Diagnostic flag to prevent POST
      level1LegacyCategoryOverride: false,
      mach2CategoryLabelOverride: false,
      isFormValid: false,
      from: null,
      isExpanded: false,
      showSnackbar: false,
      snackMessage: null
    }
  },
  computed: {
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
    isRootCategory () {
      return this.selectedCategory && this.selectedCategory.categoryLevel === 1
    },
    isAdd () {
      return this.action === 'addRootCategory' || this.action === 'addChildCategory'
    },
    isAddRoot () {
      return this.action === 'addRootCategory'
    },
    isAddChild () {
      return this.action === 'addChildCategory'
    },
    isEdit () {
      return this.action === 'editCategory'
    },
    isDelete () {
      return this.action === 'deleteCategory'
    },
    banners () {
      return this.$store.getters.banners
    },
    disableLevelCategoryLegacy () {
      if (this.isAddRoot) {
        return false
      } else if (this.isRootCategory && (this.isAddChild && !this.level1LegacyCategoryOverride)) {
        return true
      } else if (this.isRootCategory) {
        return false
      } else if (!this.level1LegacyCategoryOverride) {
        return true
      } else {
        return false
      }
    }
  },
  async mounted () {
    try {
      this.showSpinner = true
      await this.getAlternativeCategories()
      await this.getLegacyLevel1Categories()
    } catch (e) {
      console.error(e)
    } finally {
      this.showSpinner = false
    }
  },
  methods: {
    async getAlternativeCategories () {
      this.allIds = []
      await axios.get('/api/categories/alternativecategories')
        .then(res => {
          this.alternativeCategories = res.data

          // allIds is used to expand-collapse entire treeview
          this.alternativeCategories.forEach(cat => {
            this.allIds.push(cat.alternativeCategoryId)
          })

          this.mappedCategoryItems = this.alternativeCategories
            .filter(cat => cat.categoryLevel === 1)
            .map(category => {
              return {
                id: category.alternativeCategoryId,
                name: category.level1CategoryName,
                children: this.getChildren(category)
              }
            })
        })
    },
    getChildren (category) {
      const catLevel = category.categoryLevel + 1
      const children = this.alternativeCategories.filter(cat => cat.categoryLevel === catLevel && cat[`level${category.categoryLevel}CategoryId`] === category.alternativeCategoryId)
      if (children.length === 0) {
        return []
      }
      return children.map(child => {
        return {
          id: child.alternativeCategoryId,
          name: child[`level${catLevel}CategoryName`],
          children: this.getChildren(child)
        }
      })
    },
    async getLegacyLevel1Categories () {
      axios.get('/api/categories/level1legacycategories')
        .then(res => {
          this.legacyLevel1Categories = res.data
        })
    },
    async selectCategory (categoryId) {
      if (!categoryId[0]) {
        return
      }
      const selectedCat = this.alternativeCategories.find(cat => cat.alternativeCategoryId === categoryId[0])
      this.selectedNode = this.alternativeCategories.find(cat => cat.alternativeCategoryId === categoryId[0])
      this.selectedCategory = {
        alternativeCategoryId: selectedCat.alternativeCategoryId, // show (ID ####)
        categoryLevel: selectedCat.categoryLevel,
        companyId: selectedCat.companyId,
        imageDetails: {
          mediaId: null,
          mainImage: null
        },
        lineage: selectedCat.categoryLineage,
        mach2CategoryLabel: selectedCat.mach2CategoryLabel,
        mach2CategorySource: selectedCat.mach2CategorySource,
        mediaId: selectedCat.mediaId,
        name: selectedCat[`level${selectedCat.categoryLevel}CategoryName`],
        napmCategory: null,
        parentAlternativeCategoryId: selectedCat.parentAlternativeCategoryId,
        hasChildren: this.mappedCategoryItems.find(cat => cat.id === selectedCat.alternativeCategoryId)?.children.length > 0
      }
      this.getImageDetails(this.selectedCategory.mediaId)
      this.level1LegacyCategoryOverride = false
      this.mach2CategoryLabelOverride = false

      await this.$http.get(`/api/categories/${selectedCat.napmCategoryId}?languageId=1`)
        .then(res => {
          const napmDetails = res.data.details[0]
          this.selectedCategory.napmCategory = napmDetails // show 'Main Hierarchy'
        })
        .catch(e => {
          console.error(e)
        })
    },
    getFriendlyLineage (category) {
      const lineageArray = category.lineage.split(';')
      let breadCrumb = ''
      for (let i = 0; i < lineageArray.length; i++) {
        const category = this.alternativeCategories.find(cat => cat.alternativeCategoryId === Number(lineageArray[i]))
        breadCrumb += category[`level${category.categoryLevel}CategoryName`]
        if (i < lineageArray.length - 1) {
          breadCrumb += ' > '
        }
      }
      return breadCrumb
    },
    getCategoryName (category) {
      const cat = this.alternativeCategories.find(cat => cat.alternativeCategoryId === category.alternativeCategoryId)
      if (!cat) {
        return ''
      }
      return cat[`level${category.categoryLevel}CategoryName`]
    },
    imageCopyOrUpload (event) {
      if (event) {
        this.action = 'editCategory'
        this.selectedCategory.mediaId = event.mediaId
        this.selectedCategory.imageDetails = {
          mediaId: event.mediaId,
          mainImage: event.mainImage
        }
        this.doAction(this.action)
        this.save()
      }
    },
    doAction (action) {
      if (action === 'showDeleteDialog') {
        this.showDeleteDialog = true
        return
      }
      if (action === 'deleteCategory') {
        this.deleteCategory()
        return
      }
      if (action === 'addRootCategory') {
        this.selectedCategory = null
        this.level1LegacyCategoryOverride = false
        this.mach2CategoryLabelOverride = false
      }
      this.action = action
      this.editCategory = {
        selectedCategory: this.selectedCategory,
        categoryName: this.isAdd ? '' : this.selectedCategory.name,
        mach2CategoryLabel: this.isAddRoot ? null : this.selectedCategory.mach2CategoryLabel,
        napmCategoryId: this.isAddRoot ? null : this.selectedCategory.napmCategory?.id,
        mediaId: this.isAdd ? null : this.selectedCategory.mediaId
      }
      this.selectLevel1CategoryLegacy = this.legacyLevel1Categories.find(cat => cat.categoryId === this.editCategory.napmCategoryId)
      this.showEditDialog = true

      // A lot can be done here to determine if the user is overriding the inherited value
      try {
        if (this.isEdit) {
          const parentLevel1Category = this.getParentLevel1Category()
          const selectedCategoryLevel1Category = this.legacyLevel1Categories.find(cat => cat.categoryId === this.selectedCategory.napmCategory?.id)
          const parentLevel1LegacyCategory = this.legacyLevel1Categories.find(cat => cat.categoryId === parentLevel1Category?.categoryId)

          this.level1LegacyCategoryOverride = this.selectedCategory?.napmCategory.id !== parentLevel1LegacyCategory?.categoryId
          this.mach2CategoryLabelOverride = this.selectedCategory?.mach2CategoryLabel !== selectedCategoryLevel1Category?.mach2CategoryLabel
        }
      } catch (e) {
        console.error(e)
      }
      this.showEditDialog = true
    },
    getParentLevel1Category () {
      const parentOrSelfAlternativeCategoryId = this.isAddChild ? this.selectedCategory.alternativeCategoryId : this.selectedCategory.parentAlternativeCategoryId || this.selectedCategory.alternativeCategoryId
      const parentAlternativeCategory = this.alternativeCategories.find(cat => cat.alternativeCategoryId === parentOrSelfAlternativeCategoryId)
      const parentLevelCategory = this.legacyLevel1Categories.find(cat => cat.categoryId === parentAlternativeCategory?.napmCategoryId)
      const parentLevel1LegacyCategory = this.legacyLevel1Categories.find(cat => cat.categoryId === parentLevelCategory?.categoryId)
      return parentLevel1LegacyCategory
    },
    getAlternativeCategoryUpdateRequest () {
      return {
        alternativeCategoryId: this.isAdd ? 0 : this.selectedCategory.alternativeCategoryId,
        companyId: this.isAdd ? this.defaultCompanyId : this.selectedCategory.companyId,
        alternativeCategoryName: this.editCategory.categoryName,
        parentAlternativeCategoryId: this.isAddRoot ? null : this.isAddChild ? this.selectedCategory.alternativeCategoryId : this.selectedCategory.parentAlternativeCategoryId,
        // napmCategoryId: this.isAdd || this.isEdit ? this.level1LegacyCategoryOverride || this.isAddRoot ? this.editCategory.napmCategoryId : null : null,
        napmCategoryId: this.isAddRoot || (this.isRootCategory && !this.isAddChild) || this.level1LegacyCategoryOverride ? this.editCategory.napmCategoryId : null,
        mediaId: this.isAdd ? null : this.selectedCategory.mediaId,
        mach2CategoryLabel: this.isAdd || this.isEdit ? this.level1LegacyCategoryOverride || this.mach2CategoryLabelOverride ? this.editCategory.mach2CategoryLabel : null : null,
        userId: this.$store.getters.user.userId
      }
    },
    updateDescendants (category) {
      const children = this.getChildren(category)
      if (children.length === 0) {
        return
      }
      children.forEach(child => {
        const cat = this.alternativeCategories.find(cat => cat.alternativeCategoryId === child.id)
        if (cat.napmCategoryRelationshipType === 'Inherited') {
          cat.napmCategoryId = category.napmCategoryId
          cat.mach2CategoryLabel = category.mach2CategoryLabel
        }
        cat.napmCategoryId = category.napmCategoryId
        cat.mach2CategoryLabel = category.mach2CategoryLabel
        this.updateDescendants(cat)
      })
    },
    async save () {
      const alternativeCategoryUpdateRequest = this.getAlternativeCategoryUpdateRequest()
      if (this.doPost) {
        let returnedCategory = null
        this.showSpinner = true
        await axios.post('/api/categories/alternativecategory', alternativeCategoryUpdateRequest)
          .then(res => {
            if (res.data) {
              returnedCategory = res.data[0]
            }
          })
          .catch(e => {
            this.showSnackbar = true
            this.snackMessage = 'Error Saving Category'
            console.error(e)
          })
          .finally(async () => {
            this.alternativeCategories = []
            await this.getAlternativeCategories()
            if (this.isEdit) {
              const categoryIds = []
              categoryIds.push(returnedCategory.alternativeCategoryId)
              await this.selectCategory(categoryIds)
            }
            this.action = null
            this.showEditDialog = false
            this.selectLevel1CategoryLegacy = null
            this.showSpinner = false
            this.level1LegacyCategoryOverride = false
            this.mach2CategoryLabelOverride = false
          })
      }
    },
    deleteCategory () {
      this.showSpinner = true
      if (this.doPost) {
        const alternativeCategoryId = this.selectedCategory.alternativeCategoryId
        axios.delete(`/api/categories/alternativecategory/${this.selectedCategory.alternativeCategoryId}`)
          .then(res => {
          })
          .catch(e => {
            this.showSnackbar = true
            this.snackMessage = 'Error Removing Category'
            console.error(e)
          })
          .finally(async () => {
            this.selectedCategory = null
            this.showDeleteDialog = false
            this.selectLevel1CategoryLegacy = null
            await this.getAlternativeCategories()
            this.showSpinner = false

            // If the category didn't actually get deleted, select it again
            const selectedCat = this.alternativeCategories.find(cat => cat.alternativeCategoryId === alternativeCategoryId)
            if (selectedCat) {
              this.selectCategory(alternativeCategoryId)
            }
          })
      }
    },
    getImageDetails (mediaId) {
      if (!mediaId || mediaId === 0) {
        return
      }
      this.$http.get(`/api/media/images/${mediaId}`)
        .then(res => {
          if (res.data) {
            this.selectedCategory.imageDetails = {
              mediaId: res.data.mediaId,
              mainImage: res.data.folderName
            }
          } else {
            this.showSnackbar = true
            this.snackMessage = `Error Retrieving Category MediaId ${mediaId}`
            return null
          }
        })
        .catch(e => {
          this.showSnackbar = true
          this.snackMessage = 'Error Retrieving Category Media'
          console.error(e)
        })
    },
    cancel () {
      this.action = null
      this.showEditDialog = false
      this.showDeleteDialog = false
      this.selectLevel1CategoryLegacy = null
      this.showSpinner = false
      this.level1LegacyCategoryOverride = false
      this.mach2CategoryLabelOverride = false
      this.showSnackbar = false
      this.snackMessage = null
    },
    actionDisabled (action) {
      switch (action) {
        case 'editCategory':
          return !this.selectedCategory.alternativeCategoryId
        case 'addChildCategory':
          return this.selectedCategory.categoryLevel >= 3
        case 'removeCategory':
          return !this.selectedCategory.alternativeCategoryId
        case 'showDeleteDialog':
          return this.selectedCategory.hasChildren
        case 'addRootCategory':
          return false
      }
    },
    legacyLevel1CategoryName (category) {
      return `ID (${category.level1CategoryLegacyName})`
    },
    setLevel1CategoryLegacy (category) {
      this.editCategory.napmCategoryId = category.categoryId
      this.editCategory.mach2CategoryLabel = category.mach2CategoryLabel
    },
    setOverrideLevel1CategoryLegacy () {
      if (!this.level1LegacyCategoryOverride) {
        const parentLevel1LegacyCategory = this.getParentLevel1Category()
        this.selectLevel1CategoryLegacy = parentLevel1LegacyCategory
        this.editCategory.mach2CategoryLabel = parentLevel1LegacyCategory.mach2CategoryLabel
        this.mach2CategoryLabelOverride = false
      }
    },
    getLevel1CategoryLegacyItemText (item) {
      return item ? `${item.level1CategoryLegacyName} (ID ${item.categoryId})` : ''
    },
    setOverrideMach2CategoryLabel () {
      if (!this.mach2CategoryLabelOverride) {
        const level1Category = this.legacyLevel1Categories.find(cat => cat.categoryId === this.selectedCategory.napmCategory?.id)
        this.editCategory.mach2CategoryLabel = level1Category.mach2CategoryLabel
      }
    },
    getCompany (companyId) {
      return this.banners.find(banner => banner.id === companyId)
    },
    toggleTreeView () {
      if (this.isExpanded) {
        this.open = []
      } else {
        this.open = this.allIds
      }
      this.isExpanded = !this.isExpanded
    },
    filterTree () {
      if (this.search) {
        this.open = this.mappedCategoryItems
          .map(item => this.getAllIds(item))
          .flat()
      } else {
        this.open = []
      }
    },
    getAllIds (item) {
      let ids = [item.id]
      if (item.children) {
        ids = ids.concat(item.children.map(child => this.getAllIds(child)).flat())
      }
      return ids
    }
  }
}
</script>

<style scoped>
.v-dialog .v-dialog--active{
  overflow-y: hidden !important;
}
.v-row { flex-wrap: nowrap; }
</style>
