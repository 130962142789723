export default {
  ready: null,
  user: {},
  permissions: [],
  language: {
    id: 1,
    name: 'English',
    image: require('@/assets/images/icon-usa.png')
  }, // Default to English
  languageOptions: [{
    id: 1,
    name: 'English',
    image: require('@/assets/images/icon-usa.png')
  }, {
    id: 2,
    name: 'Spanish',
    image: require('@/assets/images/icon-mexico.png')
  }, {
    id: 3,
    name: 'French',
    image: require('@/assets/images/icon-french.png')
  }],
  showSpinner: false,
  modal: {
    show: false,
    title: null,
    message: '',
    icon: 'success',
    blockClose: false,
    btn: {
      text: 'Done',
      fn () {
        return this
      }
    }
  },
  productBulkList: [],
  manufacturers: [],
  vendors: [],
  pagination: {
    total: 0,
    per_page: 50,
    current_page: 1,
    last_page: 0,
    from: 0,
    to: 15
  },
  documentTypeIds: {},
  companyList: [], // Obsolete. Use banners
  banners: [],
  napmUsers: [],
  teamList: [],
  taskList: [],
  taskListProduct: {},
  taskListProductIndex: null,
  taskListPagination: {},
  incompleteTasks: 0,
  categories: {
    section: [],
    group: [],
    category: [],
    subCategory: []
  },
  accessToken: '',
  identity: null,
  appConfig: {
    identityAuthorityUrl: '',
    environment: ''
  },
  externalUrl: null,
  alternativeCategories: []
}
