<template>
  <section id="product-information">
    <div
      class="section-heading"
      :class="{ 'pb-0': compareLanguage }">
      <h6>Product Information:</h6>
      <div v-if="compareLanguage">
        <h6>Comparing to: </h6>
        <div class="btn language-button">
          <i
            class="material-icons"
            @click="cancelCompare">close</i>
          {{ currentLanguage }}
          <img
            class="language-button-icon"
            :src="$store.getters.languageOptions[compareId - 1].image" />
        </div>
      </div>
      <button
        v-else
        class="btn btn-primary btn-sm"
        @click="compareModal = true">
        Compare To Language
      </button>
      <button
        v-if="showUndoIdwButton"
        class="btn btn-sm idw-undo-btn"
        @click="undoIdwDescriptions">
        <span>Undo
          <i class="material-icons">
            undo
          </i>
        </span>
      </button>
      <idw-button
        title="Descriptons"
        section="descriptions"
        :loaded="idwLoaded"
        :has-data="idwSingleValues != null"
        :btn-name="'View IDW'"
        @openDialog="$refs.idwDescriptionsDialog.open()"></idw-button>
    </div>
    <div :class="{ 'row': compareLanguage }">
      <div
        class="section-body"
        :class="{ 'col-sm-6': compareLanguage }">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label class="form-control-label align-self-end">
              Short Description
            </label>
          </div>
          <input
            v-model="product.shortDescription"
            type="text"
            class="form-control" />
          <div
            v-if="isError"
            class="alert-danger"
            role="alert">
            <strong>Warning: </strong> Product headline length near maximum, description may be automatically truncated.
          </div>
        </div>
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label class="form-control-label align-self-end">
              Long Description
            </label>
          </div>
          <textarea
            v-model="product.description"
            class="form-control"
            rows="5"></textarea>
        </div>
        <label class="form-control-label">
          Marketing Description
        </label>
        <v-btn
          class="ml-2"
          icon
          small>
          <v-icon
            @click="showMarkdownSyntaxModal = true">
            help
          </v-icon>
        </v-btn>
        <div>
          <v-row
            justify="space-between">
            <v-col>
              <label
                class="form-control-label"
                style="font-size:.75rem;">
                Edit
              </label>

              <v-textarea
                v-model="product.marketingDescription"
                dense
                variant="solo"
                class="border"
                background-color="white"
                no-resize
                max-rows="10"
                theme="none"
                rows="10">
              </v-textarea>
            </v-col>
            <v-col>
              <label
                class="form-control-label"
                style="font-size:.75rem; padding:2px;">
                Preview
              </label>

              <div
                label="Preview"
                class="border"
                style="margin-top:3px; overflow-y:scroll;height:300px; padding:2px;"
                rows="10"
                v-html="mdToHtml">
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="form-group">
          <span class="custom-control-description">
            Made In USA
          </span>
          <select
            v-model="product.madeInUSA"
            @change="productValueChanged()">
            <option
              value="null"
              selected>
              None
            </option>
            <option value="true">
              Yes
            </option>
            <option value="false">
              No
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="form-control-label">
            Box Quantity
          </label>
          <input
            v-model="product.boxQty"
            type="text"
            class="form-control"
            @keydown="productValueChanged()" />
        </div>
      </div>
      <div class="form-group">
        <label class="form-control-label">
          Features and Benefits
        </label>
        <input
          v-for="(obj, index) in productFeatures"
          :key="index"
          v-model="obj.featureDescription"
          :placeholder="`Features and Benefits ${index+1}`"
          type="text"
          class="form-control feature-benefits"
          @input="featureValueChanged(index,$event)" />
      </div>
    </div>
    <div
      v-if="compareLanguage"
      class="section-body compare col-sm-6">
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="form-control-label align-self-end height-fix">
            Short Description
          </label>
        </div>
        <input
          v-model="compare.shortDescription"
          readonly
          type="text"
          class="form-control" />
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="form-control-label align-self-end height-fix">
            Long Description
          </label>
        </div>
        <textarea
          v-model="compare.description"
          readonly
          class="form-control"
          rows="5"></textarea>
      </div>
      <div class="form-group">
        <label class="form-control-label">
          Marketing Description
        </label>
        <editor
          id="marketing-description"
          v-model="compare.marketingDescription"
          :disabled="true"></editor>
      </div>
      <div class="form-group">
        <label class="form-control-label">
          Features and Benefits
        </label>
        <input
          v-for="(obj, index) in compare.features"
          :key="index"
          readonly
          :value="obj"
          type="text"
          class="form-control feature-benefits" />
      </div>
    </div>
    <modal
      v-if="compareModal"
      heading="Select Language to Compare"
      @close="closeCompareModal(true)">
      <div
        slot="body"
        class="modal-body">
        <state-button
          :value="1"
          :selected="compareId === 1"
          :icon="$store.getters.languageOptions[0].image"
          @selected="setCompareLanguage">
          English
        </state-button>
        <state-button
          :value="3"
          :selected="compareId === 3"
          :icon="$store.getters.languageOptions[2].image"
          @selected="setCompareLanguage">
          French
        </state-button>
        <state-button
          :value="2"
          :selected="compareId === 2"
          :icon="$store.getters.languageOptions[1].image"
          @selected="setCompareLanguage">
          Spanish
        </state-button>
      </div>
      <div
        slot="footer"
        class="action-buttons">
        <button
          class="btn btn-primary"
          :disabled="compareId === null"
          @click="setCompareData()">
          Choose this Language
        </button>
      </div>
    </modal>

    <sweet-modal
      v-if="idwSingleValues"
      ref="idwDescriptionsDialog"
      title="Descriptions">
      <idw-descriptions
        :single-values="idwSingleValues"
        @onSelected="applyIdwDescriptions($event)"></idw-descriptions>
    </sweet-modal>
    <modal
      v-if="showMarkdownSyntaxModal"
      heading="How to Use Markdown Syntax"
      @close="showMarkdownSyntaxModal = false">
      <div
        slot="body">
        <p>
          <b>Title:</b> Use hash symbols (<b>###</b> or <b>####</b>) at the beginning of a title.<br /><em><b>#</b> and <b>##</b> will be replaced with <b>###</b> and <b>####</b>, respectively.</em><br />
        </p>
        <p>
          <b>Lists:</b><br />
          - Create unordered lists using hyphens (<b>-</b>), plus signs (<b>+</b>), or asterisks (<b>*</b>) followed by a space, at the beginning of text.<br />
          - Ordered lists use numbers followed by a period and a space, at the beginning of text.<br />
        </p>
        <p>
          <b>Paragraphs:</b> Separate paragraphs with a <em>blank line</em>.<br />
        </p>
        <p>
          <b>Bold:</b> Use two asterisks (<b>**</b>) or at the beginning and end of text.<br />
        </p>
        <p>
          <b>Emphasis:</b> Use underscores (<b>_</b>) at the beginning and end of text.<br />
        </p>
        <p>
          <b>New Line:</b> Add two spaces at the end of a line(with text) or two at the start.<br />
        </p>
      </div>
    </modal>
  </section>
</template>
<script type="text/javascript">
import EditorComponent from 'components/editor'
import Modal from 'components/modals/basic'
import StateButton from 'components/stateButton'
import SweetModal from 'components/modals/sweet.vue'
import IdwButton from 'components/products/edit/idw/Button'
import IdwDescriptions from 'components/products/edit/idw/Descriptions'
import remarkHtml from 'remark-html'
import remarkParse from 'remark-parse'
import { unified } from 'unified'
import showdown from 'showdown'

export default {
  components: {
    'idw-button': IdwButton,
    'idw-descriptions': IdwDescriptions,
    'sweet-modal': SweetModal,
    editor: EditorComponent,
    modal: Modal,
    'state-button': StateButton
  },
  props: {
    idwSingleValues: Object,
    idwLoaded: Boolean,
    product: Object,
    isError: Boolean,
    productFeatures: Array
  },
  data () {
    return {
      compareLanguage: false,
      compare: {},
      compareId: null,
      compareModal: false,
      originalDescription: null,
      originalShortDescription: null,
      showUndoIdwButton: false,
      mdText: null,
      showMarkdownSyntaxModal: false,
      replacedMarketingDescription: null
    }
  },
  computed: {
    currentLanguage () {
      const id = this.compareId - 1
      return this.$store.getters.languageOptions[id].name
    },
    mdToHtml () {
      try {
        let filteredMarketingDescription = this.product.marketingDescription?.replace(/^#(?=[^#]+)/gm, '###') // Replace for <H1> tags
        filteredMarketingDescription = filteredMarketingDescription?.replace(/^##(?=[^#]+)/gm, '####') // Replace for <H2> tags
        this.replacedMarketingDescription = filteredMarketingDescription
        return new showdown.Converter().makeHtml(filteredMarketingDescription)
      } catch (e) {
        console.error(e)
        return new showdown.Converter().makeHtml(this.product.marketingDescription)
      }
    }
  },
  watch: {
    replacedMarketingDescription (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.product.marketingDescription = newVal
      }
    }
  },
  mounted () {
    this.replacedMarketingDescription = this.product.marketingDescription
  },
  methods: {
    productValueChanged () {
      this.$emit('featurechange')
    },
    featureValueChanged (key, event) {
      const newfeature = this.productFeatures[key]
      newfeature.featureDescription = event.target.value
      this.$set(this.productFeatures, key, newfeature)
      this.$emit('featurechange')
    },
    getComparisonData (languageId) {
      this.$http.get(`/api/products/${this.$route.params.id}/language-compare?languageId=${languageId}`)
        .then(res => {
          this.compareLanguage = true
          this.compare = res.data
        }).catch(e => {
          console.log(e)
        })
    },
    setCompareLanguage (value) {
      this.compareId = value
    },
    setCompareData () {
      this.getComparisonData(this.compareId)
      this.compareModal = false
    },
    closeCompareModal (reset) {
      this.compareModal = false
      if (reset) {
        this.cancelCompare()
      }
    },
    cancelCompare () {
      this.compareLanguage = false
      this.compare = {}
      this.compareId = null
    },
    applyIdwDescriptions (descriptions) {
      const desc = descriptions.find(i => i.name === 'description')
      const shortDesc = descriptions.find(i => i.name === 'shortDescription')
      if (desc) {
        this.originalDescription = this.product.description
        this.product.description = desc.value
      }
      if (shortDesc) {
        this.originalShortDescription = this.product.shortDescription
        this.product.shortDescription = shortDesc.value
      }
      this.showUndoIdwButton = true
      this.$refs.idwDescriptionsDialog.close()
    },
    undoIdwDescriptions () {
      this.showUndoIdwButton = false
      this.product.description = this.originalDescription
      this.product.shortDescription = this.originalShortDescription
    },
    async htmlText () {
      return await unified().use(remarkParse).use(remarkHtml).process('this.mdText')
    }

  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox.scss";
h6 {
  display: inline-block;
}
#product-information {
  margin-bottom: 2.25rem;
}
.compare {
  .height-fix {
    line-height: 33px;
  }
}
.feature-benefits {
  margin: 8px 0;
}
.section-heading {
  overflow: hidden;

  .idw-undo-btn {
    position: absolute;
    right: 5.75rem;

    span {
      font-size: 0.75rem;
      position: relative;
      @include flexbox;
      @include justify-content(space-between);
      i {
        padding-left: 6px;
      }
    }
  }

  .material-icons {
    font-size: 16px;
  }

  .btn-idw {
    right: 0;
  }
}
.language-button {
  position: relative;
  background: #0084d8;
  padding: 0 3rem 0 1rem;
  line-height: 40px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 1px;
  cursor: default;
  i {
    position: absolute;
    cursor: pointer;
    top: -1px;
    right: -1px;
    font-size: 1.5rem;
    padding: 0 0.5rem;
    line-height: 42px;
    &:hover {
      background: #c00;
    }
  }
}
.modal-body {
  .action-buttons {
    .btn {
      font-size: 0.825rem;
      padding: 0.75rem 1.25rem;
    }
  }
  @include flexbox;
  @include flex-wrap(wrap);
  @include justify-content(space-between);
}
</style>
