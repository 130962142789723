<template>
  <div class="card-block">
    <div class="row">
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Parts Super Center Item Number
          </label>
          <input
            v-model="product.productId"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Product Number
          </label>
          <input
            v-model="product.sku"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            PSC Price Line
          </label>
          <input
            v-model="product.mfrCode"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Buy Line
          </label>
          <input
            v-model="product.buyLine"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            UOM
          </label>
          <input
            v-model="product.uom"
            type="text"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Multiple Order Quantity
          </label>
          <input
            v-model="product.minOrdQty"
            type="number"
            class="form-control hide-arrows"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Vendor Group Code 2
          </label>
          <input
            v-model="product.vendorGroup"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Vendor Group Code 3
          </label>
          <input
            v-model="product.unitSales"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <v-select
          v-model="product.showOnWeb"
          v-tooltip="'Not set interpreted as yes for Platt and no for others'"
          class="mb-2"
          :items="showOnWebItems"
          :disabled="isReadOnly"
          hide-details
          outlined
          dense
          label="Show On Web">
        </v-select>
      </div>
      <div class="col-md-6 col-lg-3">
        <label class="custom-control custom-checkbox">
          <input
            v-model="product.callForPricing"
            type="checkbox"
            class="custom-control-input"
            :disabled="isReadOnly" />
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">
            Call For Pricing
          </span>
        </label>
      </div>
      <div class="col-md-6 col-lg-3">
        <label class="custom-control custom-checkbox">
          <input
            v-model="product.visibleOnlyToCustomers"
            type="checkbox"
            class="custom-control-input"
            :disabled="isReadOnly" />
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">
            Only Visible to Customers
          </span>
        </label>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Replaced By
          </label>
          <input
            v-model="product.bestSellerPriority"
            type="number"
            class="form-control hide-arrows"
            disabled />
        </div>
      </div>
    </div>
    <div class="section-heading">
      <h6>Alternative Category</h6>
    </div>
    <!-- <v-row>
      DIAG -> {{ editAlternativeCategoryId }}
      this.clearMode {{ clearMode }}
    </v-row> -->
    <v-row>
      <v-col cols="2">
        <v-text-field
          ref="manualEditCategoryId"
          v-model="editAlternativeCategoryId"
          label="Category Id"
          dense
          type="number"
          outlined
          hide-spin-buttons
          :error-messages="manualEditCategoryIdErrorMessages"
          :hint="selectedAlternativeCategoryName"
          persistent-hint
          @keydown.enter="blurManualEditCategoryId"
          @blur="setByCategoryId()">
        </v-text-field>
      </v-col>
      <v-col
        v-if="editAlternativeCategoryId || clearMode === 'undo'"
        cols="auto">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              dense
              tabindex="-1"
              v-bind="attrs"
              v-on="on"
              @click="clearAlternativeCategory()">
              <v-icon>
                {{ clearMode === 'undo' ? 'undo' : 'clear' }}
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{ clearMode === 'undo' ? 'Undo' : 'Clear Category' }}
          </span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="level1Category"
          :items="getCategories(1)"
          label="Level 1 Category"
          item-value="alternativeCategoryId"
          :item-text="getCategoryItemText"
          return-object
          dense
          hide-details
          @change="setCategory($event, 1)">
        </v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="level2Category"
          :items="getCategories(2)"
          label="Level 2 Category"
          item-value="alternativeCategoryId"
          :item-text="getCategoryItemText"
          return-object
          dense
          @change="setCategory($event, 2)">
        </v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="level3Category"
          :items="getCategories(3)"
          label="Level 3 Category"
          item-value="alternativeCategoryId"
          :item-text="getCategoryItemText"
          return-object
          dense
          @change="setCategory($event, 3)">
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import axios from 'axios'
import PermissionsMixin from 'mixins/Permissions.mixin'
export default {
  components: {
  },
  props: {
    product: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      marketOptions: [],
      showOnWebItems: [
        {
          text: 'Not set',
          value: null
        },
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      alternativeCategories: null,
      level1Category: null,
      level2Category: null,
      level3Category: null,
      editAlternativeCategoryId: null,
      originalEditAlternativeCategoryId: null,
      manualEditCategoryIdErrorMessages: [],
      clearMode: 'clear'
    }
  },
  computed: {
    isReadOnly () {
      return !this.$store.getters.hasPermissionForBanner(PermissionsMixin.data.Roles.contributor, 15)
    },
    // alternativeCategories () {
    //   return this.$store.getters.alternativeCategories
    // },
    // selectedAlternativeCategoryId () {
    //   if (!this.level1Category && !this.level2Category && !this.level3Category) {
    //     return null
    //   }
    //   return this.level3Category ? this.level3Category.alternativeCategoryId : this.level2Category ? this.level2Category.alternativeCategoryId : this.level1Category.alternativeCategoryId
    // },
    selectedAlternativeCategoryName () {
      if (!this.editAlternativeCategoryId) {
        return null
      }
      const category = this.alternativeCategories.find(category => category.alternativeCategoryId === this.editAlternativeCategoryId)
      if (!category) {
        return null
      }
      return this.getCategoryItemText(category)
    }
  },
  watch: {
    product: {
      handler (val) {
        this.$emit('bannerPropChange', 'Parts Super Center')
      },
      deep: true
    }
  },
  async mounted () {
    await this.getAlternativeCategories()
    this.alternativeCategories = this.$store.getters.alternativeCategories
    console.log('product', this.product)
    this.editAlternativeCategoryId = this.product?.alternativeCategoryId
    this.originalEditAlternativeCategoryId = this.editAlternativeCategoryId
    //  const selectedCategory = this.alternativeCategories.find(category => category.alternativeCategoryId === this.tempAlternativeCategoryId)
    this.setByCategoryId()
    // console.log(selectedCategory)
    // if (selectedCategory) {
    //   for (let i = selectedCategory.categoryLevel; i >= 1; i--) {
    //     this[`level${i}Category`] = this.alternativeCategories.find(category => category.alternativeCategoryId === selectedCategory[`level${i}CategoryId`])
    //   }
    // }
  },
  methods: {
    async getAlternativeCategories () {
      await axios.get('/api/categories/alternativecategories')
        .then(res => {
          this.$store.dispatch('setAlternativeCategories', res.data)
          console.log('Alternative Categories:', this.$store.getters.alternativeCategories)
        })
        .catch(res => {
          this.handleHttpError(res, 'Unable to retrieve alternative categories at this time.')
        })
    },
    getCategories (categoryLevel) {
      if (!this.alternativeCategories) {
        return []
      }
      if (categoryLevel === 1) {
        return this.alternativeCategories.filter(category => category.categoryLevel === categoryLevel)
      } else {
        console.log('level', categoryLevel)
        console.log('this', this[`level${categoryLevel}Category`]?.alternativeCategoryId)
        return this.alternativeCategories.filter(category => category.categoryLevel === categoryLevel && category[`level${categoryLevel - 1}CategoryId`] === this[`level${categoryLevel - 1}Category`]?.alternativeCategoryId)
      }
    },
    getCategoryItemText (item) {
      return item[`level${item.categoryLevel}CategoryName`]
    },
    setByCategoryId () {
      console.log('setByCategoryId', this.editAlternativeCategoryId)
      const selectedCategory = this.alternativeCategories.find(category => category.alternativeCategoryId === Number(this.editAlternativeCategoryId))
      console.log('selectedCategory', selectedCategory)
      if (selectedCategory) {
        this.setCategory(selectedCategory, selectedCategory.categoryLevel)
        for (let i = selectedCategory.categoryLevel; i >= 1; i--) {
          // console.log('id', this[`level${i}Category`])
          this[`level${i}Category`] = this.alternativeCategories.find(category => category.alternativeCategoryId === selectedCategory[`level${i}CategoryId`])
        }
      } else if (this.editAlternativeCategoryId) {
        console.log('Category not found')
        this.clearMode = 'undo'
        this.manualEditCategoryIdErrorMessages = ['Category not found']
      }
    },
    setCategory (item, categoryLevel) {
      console.log(`setCategory categoryLevel=${categoryLevel}`, item)
      this[`level${categoryLevel}Category`] = item
      console.log(this[`level${categoryLevel}Category`])
      if (categoryLevel === 1) {
        this.level2Category = null
        this.level3Category = null
      } else if (categoryLevel === 2) {
        this.level3Category = null
      }
      this.editAlternativeCategoryId = item.alternativeCategoryId
      this.manualEditCategoryIdErrorMessages = []
      this.product.alternativeCategoryId = this.editAlternativeCategoryId
    },
    blurManualEditCategoryId () {
      this.$refs.manualEditCategoryId.blur()
    },
    clearAlternativeCategory () {
      console.log('clearAlternativeCategory this.clearMode', this.clearMode)
      if (this.clearMode === 'undo') {
        this.editAlternativeCategoryId = this.originalEditAlternativeCategoryId
        this.setByCategoryId()
      } else {
        this.level1Category = null
        this.level2Category = null
        this.level3Category = null
        this.editAlternativeCategoryId = null
        this.manualEditCategoryIdErrorMessages = []
        this.product.alternativeCategoryId = null
      }
      this.clearMode = this.clearMode === 'clear' ? 'undo' : 'clear'
    }
  }
}
</script>
<style scoped>
</style>
