<template>
  <modal
    ref="crossSectionModal"
    :title="title"
    @close="resetModal()">
    <div class="cross-section">
      <div class="form-group search-products">
        <label class="form-control-label">
          Look up a product
        </label>
        <autocomplete
          response-data="products"
          @hit="selectProduct($event)"></autocomplete>
      </div>
      <div
        v-if="selectedProduct"
        class="added-products-wrap">
        <h6>Selected Product Image</h6>
        <ul class="added-products-list">
          <li :key="'added-product-' + selectedProduct.napmId">
            <div class="prod-img">
              <img
                v-if="selectedProduct.mainImage"
                :src="`https://rexel-cdn.com/products/${selectedProduct.catNum}.jpg?i=${selectedProduct.mainImage}&f=50`" />
              <img
                v-else
                :src="`https://rexel-cdn.com/products/${itselectedProductm.catNum}.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=50`" />
            </div>
            <div class="prod-info">
              <div class="row-one">
                {{ selectedProduct.catNum }}
              </div>
              <div class="row-two">
                <span>NAPM#: {{ selectedProduct.napmId }}</span>
                <span>MFR: {{ selectedProduct.manufacturer?.name }}</span>
              </div>
              <div class="row-three">
                {{ selectedProduct.description }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="alert alert-info">
        Please search and select a product to copy, then click on Copy Image button.
      </div>
    </div>
    <div
      slot="button"
      class="footer-btns">
      <button
        type="button"
        class="btn btn-default"
        @click="$refs.crossSectionModal.close()">
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        :disabled="!selectedProduct"
        @click="copyImage()">
        Copy Image
      </button>
    </div>
  </modal>
</template>
<script type="text/javascript">
import ModalComponent from '@/components/modals/sweet.vue'
// import ToggleComponent from '@/components/inputs/toggleSwitch.vue'
import AutocompleteInput from '@/components/products/search/AutocompleteInput'
export default {
  components: {
    modal: ModalComponent,
    // toggle: ToggleComponent,
    autocomplete: AutocompleteInput
  },
  props: {
    title: String,
    show: {
      default: false,
      type: Boolean,
      required: true
    },
    crosstype: String,
    crossid: Number
  },
  data () {
    return {
      selectedProduct: null
    }
  },
  computed: {

  },
  watch: {
    show (value) {
      if (value) {
        this.$refs.crossSectionModal.open()
      } else {
        this.$refs.crossSectionModal.close()
      }
    }
  },
  methods: {
    resetModal () {
      this.$emit('update:show', false)
      this.selectedProduct = null
    },
    copyImage () {
      this.$emit('save', this.selectedProduct)
      this.$refs.crossSectionModal.close()
    },
    selectProduct (product) {
      const prod = {
        napmId: product.napmId,
        catNum: product.catNum,
        manufacturer: product.manufacturer,
        mainImage: product.mainImage,
        displayOrder: 0,
        description: product.description,
        mediaId: product.mainImageId
      }
      this.selectedProduct = prod
    }
  }
}

</script>
<style lang="scss" scoped>
@import '../../../assets/scss/extra/mixins/flexbox';
@import '../../../assets/scss/bootstrap-custom-variables';
@import '../../../../node_modules/bootstrap/scss/bootstrap';

.cross-section {
  min-height: 22rem;
}

.footer-btns {
  padding: 0.25rem 0.5rem;
}

.search-products {
  position: relative;
  ul.product-result-list {
    list-style: none;
    position: absolute;
    top: 3.95rem;
    background: #fff;
    width: 527px;
    max-height: 250px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding-left: 0;
    overflow-y: scroll;
    z-index: 99;
    .product-result {
      &:not(:first-child) {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
      }
      &:hover {
        cursor: pointer;
        background: #eee;
        .result-action .btn {
          @extend .btn-primary;
          @include transition(none);
        }
      }
      @include flexbox;
      .result-img {
        @include flex(0, 1, 3.75rem);
        @include align-self(center);
        text-align: center;
        > img {
          display: inline-block;
        }
      }
      .result-info {
        @include flex(1, 1, 75%);
        @include align-self(center);

        padding: 0.25rem 0.5rem;
        .row-one,
        .row-two {
          font-weight: 500;
          font-size: 0.875rem;
          text-transform: uppercase;
          > span {
            padding-right: 0.75rem;
          }
        }
        .row-two {
        }
        .row-three {
          color: #999;
          width: 21.75rem;
          font-size: 0.75rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .result-action {
        @include flex(0, 1, 3.75rem);
        @include align-self(center);
        text-align: center;
      }
    }
  }
}

.added-products-wrap {
  > h6 {
  }
  ul.added-products-list {
    list-style: none;
    padding-left: 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    li {
      @include flexbox;
      &:not(:first-child) {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
      }
      .prod-img {
        @include flex(0, 1, 3.75rem);
        @include align-self(center);
        text-align: center;
        img {
          display: inline-block;
        }
      }
      .prod-info {
        @include flex(1, 1, 75%);
        padding: 0.25rem 0.5rem;
        .row-one,
        .row-two {
          font-weight: 500;
          font-size: 0.875rem;
          text-transform: uppercase;
          > span {
            padding-right: 0.75rem;
          }
        }
        .row-three {
          color: #999;
          width: 21.75rem;
          font-size: 0.75rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .cross-check {
        @include flex(0, 1, 4.75rem);
        @include align-self(center);
      }
      .remove-prod {
        @include flex(0, 1, 3rem);
        @include align-self(center);
        text-align: center;
        a {
          display: block;
          font-size: 1.5rem;
          color: $brand-danger;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
