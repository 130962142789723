<template>
  <div class="category-image">
    <div
      class="image-wrap"
      :class="{'is-invalid': invalid}">
      <div class="img-fluid">
        <img :src="getMediaImagePath({ folder: details.mainImage, size: 77 })" />
      </div>
      <div class="image-upload">
        <span>Upload Image</span>
        <template v-if="!imageReset">
          <input
            type="file"
            :accept="validMediaFileTypes.images"
            @change="imageValidate($event)" />
        </template>
      </div>
      <div class="image-copy">
        <a @click="copyImageModal = true">
          Copy Image
        </a>
      </div>
    </div>
    <modal
      ref="uploadCategoryImage"
      :title="modal.title"
      :icon="modal.icon"
      :animate-icon="modal.animate"
      :blocking="true"
      :large="true"
      :no-padding="true"
      @close="resetState()">
      <template v-if="uploadMessage">
        <p class="lead text-center">
          {{ modal.message }}
        </p>
      </template>
      <template v-else-if="imageUpload">
        <uploading :loading="loading"></uploading>
        <div :class="{'uploading-media': loading}">
          <cropper
            ref="imgCropper"
            :enable-exif="true"
            :show-zoomer="false"
            :show-buttons="true"
            :zoom-level="imgZoom"
            :mouse-wheel-zoom="true"
            :enforce-boundary="false"
            :enable-orientation="true"
            :boundary="{height: 450, width: 768}"
            :viewport="{ width: 400, height: 400, type: 'square' }"
            @update="imageCropUpdate($event)"
            @rotate="rotated = $event">
          </cropper>
        </div>
      </template>
      <template v-else>
        <upload-zone
          :document="false"
          :error="modal.error"
          :message="modal.message"
          :message-type="modal.messageType"
          @upload="imageValidate($event)"></upload-zone>
      </template>
      <div
        slot="button"
        class="modal-actions">
        <template v-if="uploadMessage && !loading">
          <span class="spacer"></span>
          <button
            type="button"
            class="btn btn-gray-alt"
            @click="resetState()">
            Done
          </button>
        </template>
        <template v-else>
          <button
            type="button"
            class="btn btn-link"
            :disabled="loading"
            @click="resetState()">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="loading"
            @click="imageSave($event)">
            {{ loading ? 'Sending Image' : 'Upload Image' }}
          </button>
        </template>
      </div>
    </modal>
    <copyImage-modal
      :show.sync="copyImageModal"
      title="Copy Image from Product"
      @save="imageCopy($event, true)"></copyImage-modal>
  </div>
</template>
<script>
import axios from 'axios'
import Cropper from 'components/crop'
import Modal from 'components/modals/sweet'
import Uploading from '@/views/media/upload/uploading'
import MediaFileTypesMixin from '@/views/media/types/MediaFileTypes.mixin'
import MediaFilePathMixin from '@/views/media/types/MediaFilePath.mixin'
import UploadZone from '@/views/media/upload/uploadZone.vue'
import CopyImageModal from '@/views/categories/components/copyImageFromProduct.vue'

export default {
  name: 'DetailMedia',
  components: {
    modal: Modal,
    cropper: Cropper,
    uploading: Uploading,
    'upload-zone': UploadZone,
    'copyImage-modal': CopyImageModal
  },
  mixins: [MediaFileTypesMixin, MediaFilePathMixin],
  props: {
    details: Object,
    invalid: Boolean,
    modalWithinModal: Boolean,
    fromAlternativeCategories: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modal: {
        title: 'Upload Category Image',
        icon: null,
        animate: false,
        message: '',
        messageType: '',
        error: false
      },
      copyImageModal: false,
      file: null,
      fileName: null,
      image: null,
      imageUpload: false,
      uploadModal: false,
      uploadMessage: false,
      rotated: 0,
      imgZoom: 1,
      imgPoints: {},
      loading: false,
      imageReset: false
    }
  },
  watch: {
    uploadModal (value) {
      if (value) {
        this.$refs.uploadCategoryImage.open()
      } else {
        this.$refs.uploadCategoryImage.close()
      }
    }
  },
  methods: {
    imageValidate (event) {
      this.file = event.target.files[0]
      this.fileName = event.target.files[0].name
      if (this.file.type.indexOf('image/') > -1) {
        this.uploadModal = true
        this.imageInit(event)
      } else {
        // throw error
      }
    },
    imageInit (event) {
      const image = new Image()
      const reader = new FileReader()
      reader.onload = (file) => {
        this.image = file.target.result
        image.src = file.target.result
      }
      reader.onloadend = (file) => {
        this.imageUpload = true
        this.$nextTick(() => {
          this.$refs.imgCropper.bind({
            url: this.image
          })
        })
      }
      reader.readAsDataURL(event ? this.file : false)
    },
    imageCropUpdate (event) {
      this.imgZoom = event.zoom
      this.imgPoints = event.points
    },
    imageCopy (event) {
      if (event) {
        this.details.mediaId = event.mediaId
        this.details.folderName = event.folderName
        this.details.mainImage = event.mainImage
        if (this.fromAlternativeCategories) {
          this.$emit('save', event)
        }
      }
    },
    imageSave (event) {
      event.preventDefault()
      const data = new FormData()
      data.append('file', this.file)
      data.append('x1', this.imgPoints[0])
      data.append('y1', this.imgPoints[1])
      data.append('x2', this.imgPoints[2])
      data.append('y2', this.imgPoints[3])
      data.append('fileName', this.fileName)
      data.append('rotate', this.rotated ? this.rotated * 90 : null)

      this.loading = true
      axios.post('/api/media/images', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
        const result = res.data
        this.loading = false
        this.uploadMessage = true
        if (res.status === 200) {
          this.modal.message = 'Category image has been successfully uploaded and updated.'
          this.modal.icon = 'success'
          this.details.mediaId = result.mediaId
          this.details.mainImage = result.folderName
          if (this.fromAlternativeCategories) {
            this.$emit('save', this.details)
          }
        } else {
          this.modal.message = 'Errors proccessing Category image. Please try again later or contact your administrator.'
          this.modal.icon = 'error'
        }
        setTimeout(() => {
          this.$refs.uploadCategoryImage._animateIcon()
        }, 50)
      })
    },
    resetState () {
      this.modal = {
        title: 'Upload Category Image',
        icon: null,
        animate: false,
        message: '',
        messageType: '',
        error: false
      }
      this.file = null
      this.image = null
      this.imageUpload = false
      this.uploadModal = false
      this.uploadMessage = false
      this.rotated = 0
      this.imgZoom = 1
      this.imgPoints = {}
      this.imageReset = true
      this.$nextTick(() => {
        // resetting image input value
        this.imageReset = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/extra/mixins/flexbox';
@import '../../../assets/scss/bootstrap-custom-variables';
.category-image {
  @include flex(0, 1, 5.75rem);
  .image-wrap {
    .img-fluid {
      border: 1px solid rgba(0, 0, 0, 0.125);
      padding: 0.125rem;
      min-height: 5.75rem;
      min-width: 5.75rem;
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
    }
    .image-upload,
    .image-copy {
      position: relative;
      font-size: 0.8125rem;
      letter-spacing: 0.75px;
      text-align: center;
      color: $gray-600;
      padding-top: 0.25rem;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        color: $brand-primary;
      }
      input {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
    &.is-invalid {
      .image-upload {
        color: $brand-danger;
        white-space: nowrap;
        font-weight: bold;
      }
      .img-fluid {
        border-color: $brand-danger;
      }
    }
  }
  .modal-actions {
    @include flexbox;
    @include justify-content(space-between);
  }
}
</style>
